
import React, { useState, useCallback, useRef, useEffect } from 'react';

import LayoutHome from '../layouts/LayoutHome';
import config from '../config';
import { createCustomStore, interleave, pathFile, request } from '../utils/api';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import Moment from 'react-moment';
import { LoadPanel } from 'devextreme-react';
import Pagination from "react-js-pagination";
import { Helmet } from 'react-helmet';

export default function VanBanQuocTe() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState();
  const [vanban, setVanban] = useState();
  const [lopvanbans, setLopvanbans] = useState();
  const [lopvanbankhacs, setLopvanbankhacs] = useState();

  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [items, setItems] = useState();
  const searchForm = useRef();
  const id = params.id;
  const LopVanBanID = searchParams.get("LopVanBanID");

  useEffect(() => {
    (async function () {
      const lopvanbans = await request(`Public/LopVanBan?filter=["LopVanBanCha","=","105"]`);
      setLopvanbans(lopvanbans);
      const lopvanbankhacs = await request(`Public/LopVanBan?filter=["LopVanBanCha","=","1117"]`);
      setLopvanbankhacs(lopvanbankhacs);
      const bans = await request(`/Public/BannerByViTri?ID_ViTri=3210`);
      setBanners(bans);

      if (id) {
        const bans = await request(`/Public/VanBanDetail?ID=${id}`);
        if (bans.data != null) {
          setVanban(bans);
        }
      }
    })();
  }, [])

  useEffect(() => {
    if (lopvanbans) {
      reloadData(0);
    }
  }, [lopvanbans])

  async function reloadData(newOffset) {
    setLoading(true);

    let filter = getFilters();
    let filterStr = JSON.stringify(interleave(filter, "and"));
    const vbs = await request(`Public/VanBanByLopVanBan?id=105&lang=vi&skip=${newOffset}&take=${itemsPerPage}&requireTotalCount=true&filter=${filterStr}`);
    setTotalCount(vbs.totalCount);
    setItems(vbs.data);

    setLoading(false);
  }

  const getFilters = () => {
    let form = searchForm.current;
    let filter = [];
    if (form.lopvanban.value) {
      filter.push(["LopVanBanID", "=", form.lopvanban.value]);
    }

    if (form.keyword.value) {
      filter.push(["TrichYeu", "contains", form.keyword.value]);
    }
    if (form.fromdateNGN.value) {
      filter.push(["NgayGiaNhap", ">=", form.fromdateNGN.value]);
    }
    if (form.todateNGN.value) {
      filter.push(["NgayGiaNhap", "<=", form.todateNGN.value]);
    }
    return filter;
  }
  const handleSearch = async (event) => {
    event.preventDefault();
    reloadData(0);
  }
  // Invoke when user click to request another page.
  const handlePageClick = async (pageNumber) => {
    const newOffset = ((pageNumber - 1) * itemsPerPage) % totalCount;
    setActivePage(pageNumber);
    reloadData(newOffset);
  };
  return (
    <>
      {!vanban &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-Văn bản hợp tác quốc tế`}</title>
        </Helmet>
      }
      {vanban &&
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`BỘ LAO ĐỘNG - THƯƠNG BINH VÀ XÃ HỘI-${vanban.data.SoKyHieu}`}</title>
        </Helmet>
      }
      <LoadPanel visible={loading} />
      <div className="breadcrumb">
        <a href="/" className="breadcrumb-item">Trang chủ</a>
        <a href="/vanbanquocte" class="breadcrumb-item">Văn bản hợp tác quốc tế</a>
        {vanban &&
          <a href={`/vanbanquocte/${vanban.data.ID}`} class="breadcrumb-item">{vanban.data.SoKyHieu}</a>
        }
      </div>
      <div className='row'>
        <div className="col-sm-3 col-12">
          <ul className="list-document-cat">
            <li className="selected">
              <div className="down" />
              <a href="/vanbanquocte?LopVanBanID=105">Văn bản Hợp tác quốc tế</a>
              <ul>
                {lopvanbans && lopvanbans.data.map((item) =>
                  <li>
                    <a className={`t-title ${LopVanBanID == item.ID ? "bold" : ""}`} href={`/vanbanquocte?LopVanBanID=${item.ID}`}>{item.TenLopVanBan}</a>
                  </li>
                )}
              </ul>
            </li>
            <li className="selected">
              <div className="down" />
              <a href="/vanbanquocte?LopVanBanID=1117">Văn bản quốc tế khác</a>
              <ul>
                {lopvanbankhacs && lopvanbankhacs.data.map((item) =>
                  <li>
                    <a className={`t-title ${LopVanBanID == item.ID ? "bold" : ""}`} href={`/vanbanquocte?LopVanBanID=${item.ID}`}>{item.TenLopVanBan}</a>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
        {!vanban &&
          <div className="main-left col-sm-9 col-12">
            <form ref={searchForm} onSubmit={handleSearch}>
              <div className="box-search mb-3">
                <div className="input-search">
                  <div className="input-icon-left">
                    <input type="text" className="form-control" id='keyword' placeholder="Nhập tên, nội dung... tìm kiếm" />
                    <span className="isax-search-normal1 icon" />
                  </div>
                  <button className="btn-success btn-search btn" type='submit'>Tìm kiếm</button>
                </div>
                <div className="btn-search-adv btn" style={{ display: 'inline-block' }} data-toggle="collapse" data-target="#searchadv">Tìm kiếm nâng cao</div>
                <div className="box-search-adv" id="searchadv">
                  <div className="form-group">
                    <div className="label-text">Tìm kiếm theo loại</div>
                    <div className="input">
                      <div className="select-custom">
                        <select id="lopvanban" className="form-control">
                          <option value="">--Tất cả--</option>
                          {lopvanbans && lopvanbans.data.map((item) =>
                            <option value={item.ID} selected={LopVanBanID == item.ID}>{item.TenLopVanBan}</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="label-text">Ngày gia nhập</div>
                    <div className="input">
                      <div className="row">
                        <div className="col-sm-6 col-12">
                          <div className="input-icon">
                            <input type="date" className="form-control" placeholder="dd/mm/yyyy" id="fromdateNGN" />
                            <span className="icon fa fa-calendar" />
                          </div>
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="input-icon">
                            <input type="date" className="form-control" placeholder="dd/mm/yyyy" id="todateNGN" />
                            <span className="icon fa fa-calendar" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="actions tright">
                    <button className="btn btn-success" type='submit'>
                      Tìm kiếm
                    </button>
                    <button className="btn btn-default" id="btn-close" type='button' data-toggle="collapse" data-target="#searchadv">
                      Huỷ
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: '50px' }}>STT</th>
                    <th>Văn bản</th>
                    <th> Thuộc Tính</th>
                  </tr>
                </thead>
                <tbody>

                  {items && items.map((item, index) =>
                    <tr>
                      <td> {index + 1}</td>
                      <td> <a href={`/vanbanquocte/${item.ID}`}> {item.SoKyHieu}</a> </td>
                      <td class="text-left w-25">
                        {item.NgayBanHanh && <>
							Ngày ban hành:{' '}
                          <strong>
                            <Moment format="DD/MM/YYYY">
                              {item.NgayBanHanh}
                            </Moment>
                          </strong>
                        </>
                        }
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="navi-page">
              <div className="text-result"><strong>{totalCount}</strong> văn bản</div>
              <Pagination
                activePage={activePage}
                itemClass='page-item'
                linkClass='page-link'
                itemsCountPerPage={itemsPerPage}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                onChange={handlePageClick}
              />
            </div>
          </div>
        }
        {vanban &&
          <>
            <div className="main-left col-sm-9 col-12">
              <ul className="document-tabs nav">
                <li className="nav-item"><a href="#tabAttr" className="active nav-link" data-toggle="tab" aria-expanded="true">THUỘC TÍNH </a></li>
                <li className="nav-item"><a href="#tabcontent" data-toggle="tab" className="nav-link" aria-expanded="false">NỘI DUNG </a></li>
                <li className="nav-item"><a href="#tabdownload" data-toggle="tab" className="nav-link" aria-expanded="false"> TẢI VỀ </a></li>
                <li className="nav-item"><a href="#tabRelates" data-toggle="tab" className="nav-link">VĂN BẢN LIÊN QUAN </a></li>
              </ul>
              <div className="tab-content">
                <div id="tabAttr" className="tab-pane active">
                  <div className="attr-box relative">
                    {vanban.data.NgayGiaNhap &&
                      <div className="join-status -joined">Đã gia nhập</div>
                    }
                    {!vanban.data.NgayGiaNhap &&
                      <div className="join-status">Chưa gia nhập</div>
                    }
                    <div className="info">
                      <div className="key">
                        Tên văn bản:
                      </div>
                      <div className="value">
                        {vanban.data.TrichYeu}
                      </div>
                    </div>
                    {vanban.data.VanBan_LinhVuc && vanban.data.VanBan_LinhVuc.length > 0 &&
                    <div className="info">
                      <div className="key">
                        Lĩnh vực văn bản:
                      </div>
                      <div className="value">
                        {vanban.data.VanBan_LinhVuc.map((item, index) =>
                          <>
                            <p><strong>{item.LinhVuc.TenLinhVuc} </strong></p>
                          </>
                        )}
                      </div>
                    </div>
                    }
                    {vanban.data.VanBan_CoQuanBanHanh &&  vanban.data.VanBan_CoQuanBanHanh.length > 0 &&
                    <div className="info">
                      <div className="key">
                        Tổ chức ban hành:
                      </div>
                      <div className="value">
                        {vanban.data.VanBan_CoQuanBanHanh.map((item, index) =>
                          <>
                            {item.CoQuanBanHanh.TenCoQuanBanHanh} <br />
                          </>
                        )}
                      </div>
                    </div>
                    }
                    {vanban.data.NgayBanHanh && 
                    <div className="info">
                      <div className="key">
                        Ngày ban hành:
                      </div>
                      <div className="value">
                        <p><strong>
                          <Moment format="DD/MM/YYYY">
                            {vanban.data.NgayBanHanh}
                          </Moment>
                        </strong></p>
                      </div>
                    </div>
                    }
                  </div>
                </div>
                <div className="tab-pane" id="tabcontent">
                  {vanban.data.VanBan_Files && vanban.data.VanBan_Files.map((item, index) =>
                    <>
                      <iframe src={`https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(pathFile(item.File.LinkLuuTru))}`} style={{ width: '100%', height: '400px' }}></iframe>
                    </>
                  )
                  }
                </div>
                <div id="tabdownload" className="tab-pane">
                  <div className="attr-box">
                    <div className="info">
                      <div className="key">
                        Tên văn bản:
                      </div>
                      <div className="value">
                        {vanban.data.TrichYeu}
                      </div>
                    </div>
                    <div className="info">
                      <div className="key">
                        Tải về:
                      </div>
                      <div className="value">
                        {vanban.data.VanBan_Files && vanban.data.VanBan_Files.map((item, index) =>
                          <>
                            <a href={pathFile(item.File.LinkLuuTru)}>{item.File.Ten}</a> <br />
                          </>
                        )
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="tabRelates">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={{ width: '50px' }}>#</th>
                          <th>Văn bản</th>
                          <th> Thuộc Tính</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vanban.data.VanBan_LienQuans && vanban.data.VanBan_LienQuans.map((item, index) =>
                          <>
                            <tr>
                              <td>{index + 1} </td>
                              <td> <a href={`/vanbanquocte/${item.VanBan.ID}`}>{item.VanBan.TrichYeu}</a></td>
                              <td>
                                {item.VanBan.NgayBanHanh && <>
                                  Ngày Ban Hành:
                                  <strong>
                                    <Moment format="DD/MM/YYYY">
                                      {item.VanBan.NgayBanHanh}
                                    </Moment>
                                  </strong>
                                </>
                                }
                              </td>
                            </tr>
                          </>
                        )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </>
  );
}
